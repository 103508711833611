import Aside from "../../components/Aside/Aside.vue";
import Header from "../../components/Header/Header.vue";
import ExamBlock from "../../components/ExamTest/ExamTest.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
    name: "Favorites",
    components: {
        Aside,
        Header,
        ExamBlock,
        DownloadPage,
    },
    setup() {
        const store = useStore();
        const showCard = ref(false);
        const delFavorite = (user_id, question_id) => {
            // console.log('user_id', user_id);
            // console.log('question_id', question_id);
            store.dispatch("DEL_FAVORITES", { user_id, question_id });
        };
        const select = () => {
            showCard.value = true;
        }
        onMounted(() => {
            const userData = store.getters.userInfo
            const getFavorites = userId => store.dispatch('GET_FAVORITES', userId)
            getFavorites(userData.id)
        })

        return {
            favorites: computed(() => store.getters.FAVORITES),
            userData: computed(() => store.getters.userInfo),
            delFavorite,
            select,
            showCard
        };
    },
};
