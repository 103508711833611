<template>
  <div class="regDescription">
    <p class="regDescription__text">Первый урок программы доступен без регистрации</p>
    <p class="regDescription__text">После регистрации вам будет доступно решение билетов в&nbsp;вкладке Свой экзамен, Экзамен ГИБДД</p>
    <p class="regDescription__text">Второй урок откроется после регистрации на&nbsp;обучение в&nbsp;автошколе</p>
    <p class="regDescription__text">Если вы&nbsp;не&nbsp;записались на&nbsp;обучение, то&nbsp;<a class="regDescription__link" href="https://wa.me/+79080913383" target="_blank">напишите</a> или позвоните <a class="regDescription__link" href="tel:+79080913383">+79080913383</a> администратору автошколы.</p>
  </div>
</template>

<script>
export default {
  name: 'RegDescription'
}
</script>

<style lang="scss">
  .regDescription {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 560px;
    font-size: 24px;
    line-height: 26px;

    &__text {
      margin: 0;
      text-align: center;
    }

    &__link {
      color: #3C6BE4;
      text-decoration: none;
    }
  }
</style>
