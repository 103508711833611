<template>
  <div class="theory page">
    <Aside></Aside>
    <div class="container">
      <Header></Header>
      <div class="content theory__container" v-if="lastTheory">
        <Preloader v-if="preloaderActive"></Preloader>

        <!-- Таймер -->
        <!-- <div class="theory-timer" v-if="userData && !examAccess && !userData.free_access">
                    <svg width="24" height="24" class="header-exam__ico" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 2C9.02219 2 7.08879 2.58649 5.4443 3.6853C3.79981 4.78412 2.51809 6.3459 1.76121 8.17317C1.00433 10.0004 0.806299 12.0111 1.19215 13.9509C1.578 15.8907 2.53041 17.6725 3.92894 19.0711C5.32746 20.4696 7.10929 21.422 9.0491 21.8079C10.9889 22.1937 12.9996 21.9957 14.8268 21.2388C16.6541 20.4819 18.2159 19.2002 19.3147 17.5557C20.4135 15.9112 21 13.9778 21 12C21 10.6868 20.7413 9.38642 20.2388 8.17317C19.7363 6.95991 18.9997 5.85752 18.0711 4.92893C17.1425 4.00035 16.0401 3.26375 14.8268 2.7612C13.6136 2.25866 12.3132 2 11 2V2ZM11 20C9.41775 20 7.87104 19.5308 6.55544 18.6518C5.23985 17.7727 4.21447 16.5233 3.60897 15.0615C3.00347 13.5997 2.84504 11.9911 3.15372 10.4393C3.4624 8.88743 4.22433 7.46197 5.34315 6.34315C6.46197 5.22433 7.88743 4.4624 9.43928 4.15372C10.9911 3.84504 12.5997 4.00346 14.0615 4.60896C15.5233 5.21447 16.7727 6.23984 17.6518 7.55544C18.5308 8.87103 19 10.4177 19 12C19 14.1217 18.1572 16.1566 16.6569 17.6569C15.1566 19.1571 13.1217 20 11 20V20ZM14.1 12.63L12 11.42V7C12 6.73478 11.8946 6.48043 11.7071 6.29289C11.5196 6.10536 11.2652 6 11 6C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V12C10 12 10 12.08 10 12.12C10.0059 12.1889 10.0228 12.2564 10.05 12.32C10.0706 12.3793 10.0974 12.4363 10.13 12.49C10.1574 12.5468 10.1909 12.6005 10.23 12.65L10.39 12.78L10.48 12.87L13.08 14.37C13.2324 14.4564 13.4048 14.5012 13.58 14.5C13.8014 14.5015 14.0171 14.4296 14.1932 14.2953C14.3693 14.1611 14.4959 13.9722 14.5531 13.7583C14.6103 13.5444 14.5948 13.3176 14.5092 13.1134C14.4236 12.9092 14.2726 12.7392 14.08 12.63H14.1Z" fill="#3C6BE4"></path></svg>
                    {{setNormalTime}}
                </div> -->

        <!-- Заголовок -->
        <TheoryTitle
          :index="lastTheory.index"
          :name1="lastTheory.name1"
          :name2="lastTheory.name2"
        ></TheoryTitle>

        <div class="theory__video-block" v-if="lastTheory">
          <!-- Видео плеер -->
          <VideoPlayer
            v-if="lastTheory.id"
            :data="lastTheory.video"
            :id="lastTheory.id"
            :accessProgress="accessProgress"
            @onHandlerPropgress="accessProgressHandler"
            @onHandlerPreloader="preloaderOff"
          ></VideoPlayer>
        </div>
        <div class="theory__info">
          <!-- Предыдущий урок -->
          <TheoryPrev
            @click="prevVideoHandler"
            :data="{ index: lastTheory.index, ...prevTheory.value }"
            v-if="prevTheory.value && lastTheory.index !== 0"
          ></TheoryPrev>

          <!-- Пройти тест -->
          <TheoryNext
            :theoryData="lastTheory"
            :accessProgress="accessProgress"
            v-if="
              !userData || (userData && userData.free_access) || accessProgress
            "
          ></TheoryNext>

          <div
            v-if="userData && !userData.free_access && !accessProgress"
            class="theory-next theory-next--disabled"
          >
            Пройти тест
          </div>

          <!-- Пройти экзамен -->
          <router-link
            v-if="
              (userData && userData.free_access) ||
              (examAccess && accessProgress)
            "
            class="theory-next"
            :to="{
              name: 'ExamTest',
              params: {
                item: JSON.stringify(lastTheory),
                showHelpers: false,
                testExam: true,
                errors: 1,
              },
            }"
          >
            Пройти экзамен
          </router-link>
        </div>
      </div>
    </div>
    <DownloadPage></DownloadPage>
  </div>
</template>

<script>
import { onMounted, ref, computed, reactive, watch } from 'vue'
import Aside from '../components/Aside/Aside.vue'
import Header from '../components/Header/Header.vue'
import DownloadPage from '../components/DownloadPage/DownloadPage.vue'
import VideoPlayer from '../components/VideoPlayer/VideoPlayer.vue'
import TheoryTitle from '../components/TheoryTitle.vue'
import TheoryNext from '../components/TheoryNext.vue'
import TheoryPrev from '../components/TheoryPrev.vue'
import { useStore } from 'vuex'
import Preloader from './../components/Preloader/Preloader'
import router from './../router'

export default {
  name: 'Theory',
  components: {
    Aside,
    Header,
    DownloadPage,
    VideoPlayer,
    TheoryTitle,
    TheoryNext,
    TheoryPrev,
    Preloader,
  },

  setup() {
    const store = useStore()

    const accessProgress = ref(false)

    const preloaderActive = ref(true)

    const prevTheory = reactive({})

    const allTheory = reactive(computed(() => store.getters.THEORY_LIST))

    const lastTheory = reactive(computed(() => store.getters.LAST_THEORY))

    const timerTheory = reactive(computed(() => store.getters.TIMER))

    const watchedVideos = computed(() => store.getters.VIDEOS)

    const userData = reactive(computed(() => store.getters.userInfo))

    const examAccess = ref(false)

    //таймер
    const setSeconds = ref('') //1200000
    const setNormalTime = ref('')
    const interval = ref(null)

    const tranformTime = (millisec) => {
      let seconds = (millisec / 1000).toFixed(0)
      let minutes = Math.floor(seconds / 60)
      let hours = '00'
      if (minutes > 59) {
        hours = Math.floor(minutes / 60)
        hours = hours >= 10 ? hours : '00' + hours
        minutes = minutes - hours * 60
        minutes = minutes >= 10 ? minutes : '00' + minutes
      }
      seconds = Math.floor(seconds % 60)
      seconds = seconds >= 10 ? seconds : '0' + seconds
      return hours + ':' + minutes + ':' + seconds
    }

    // const start = (time) => {
    //     setSeconds.value = time;
    //     interval.value = setInterval(() => {
    //         if(setSeconds.value > 1000) {
    //             setSeconds.value = setSeconds.value - 1000;
    //             setNormalTime.value = tranformTime(setSeconds.value - 1000)
    //         }
    //         else if (false) {
    //             return false;
    //         }
    //         else if(setSeconds.value <= 1000){
    //             clearInterval(interval.value);
    //             setSeconds.value = 0;
    //             examAccess.value = true;
    //         }
    //     }, 1000);
    // }

    // const timer = () => {
    //     setSeconds.value = timerTheory.value.current_time - currentVideo.value.timestamp
    // }

    //Убрать прелоадер
    const preloaderOff = () => {
      preloaderActive.value = false
    }

    const getLastTheory = () => store.dispatch('GET_LAST_THEORY')

    const getPrevTheory = () => {
      prevTheory.value = allTheory.value[lastTheory.value.index - 1]
    }

    const currentVideo = reactive({})

    const prevVideoHandler = async () => {
      // await store.dispatch("UPDATE_LAST_THEORY", {
      //     index: lastTheory.value.index - 1,
      //     ...prevTheory.value,
      // });
      // await getPrevTheory();
      examAccess.value = true
      router.push({ name: 'Home' })
    }

    const filterVideo = async () => {
      const userId = await localStorage.getItem('userData')
      if (userId !== null) {
        await store.dispatch('GET_TIMER', userId)
        await store.dispatch('GET_VIDEOS', userId)

        if (watchedVideos.value.length) {
          accessProgress.value = await watchedVideos.value.some(
            (i) => i.id == lastTheory.value.id
          )
        }

        const arr = timerTheory.value.mark_video.filter(
          (i) => i.id == lastTheory.value.id
        )

        currentVideo.value = { ...arr[0] }
      }
    }

    const accessProgressHandler = () => {
      // console.log('Open progress')
      accessProgress.value = true
    }

    onMounted(async () => {
      await getLastTheory()
      await getPrevTheory()
      await filterVideo()

      if (timerTheory.value.mark_video) {
        timerTheory.value.mark_video.forEach((i) => {
          if (i.id == currentVideo.value.id) {
            examAccess.value = true
          }
        })
      }
      // await timer();

      // if (setSeconds.value < 3600000) {
      //     const newTime = 3600000 - parseInt(setSeconds.value)
      // 	start(newTime)
      // }
      // else if (setSeconds.value > 3600000) {
      //     examAccess.value = true
      // }
      // else {
      //     console.log ('ниче');
      // }
    })

    // watch(accessProgress.value, () => {
    //   console.log('WATCH')
    // })

    return {
      lastTheory,
      prevTheory,
      prevVideoHandler,
      timerTheory,
      accessProgress,
      allTheory,
      currentVideo,
      setNormalTime,
      accessProgressHandler,
      examAccess,
      preloaderActive,
      preloaderOff,
      watchedVideos,
      userData,
    }
  },
}
</script>

<style lang="scss">
.theory-timer {
  display: flex;
  align-items: center;
  color: #2d3548;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  right: 12px;
  top: 75px;
  @media (max-width: 1500px) {
    top: 30px;
  }
}
.theory {
  &__container {
    flex-direction: column;
    width: 1050px;
    margin: 0 auto;
    padding-top: 65px;
    zoom: 0.9;
    justify-content: start !important;
    @media (max-width: 1500px) {
      padding-top: 10px;
    }
  }

  &__video-block {
    width: 100%;
    height: 605px;
    background: #ffffff;
    box-shadow: 32px 32px 62px rgba(228, 216, 216, 0.6);
    border-radius: 15px;
    padding: 18px;
    margin-bottom: 22px;
    @media (max-width: 1500px) {
      height: 400px;
    }
  }
  &__info {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

input[type='range'] {
  border-radius: 2px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(255, 255, 255, 0.7);
  height: 1px;
  border: none;
  cursor: pointer;
}

input[type='range']::-moz-range-track {
  vertical-align: middle;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  -moz-appearance: none;
  background-color: white;
  height: 1px;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: white;
  border: 0;
}

input[type='range'].light::-moz-range-thumb {
  box-shadow: 0 0 8px 2px white;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: white;
  border: 0;
}

input[type='range'].light::-webkit-slider-thumb {
  box-shadow: 0 0 8px 2px white;
}

input[type='range']:active::-moz-range-track {
  background: orange;
}

.seeker {
  position: relative;
  width: 65%;
  margin: 0 1%;
  z-index: 0;
  display: inline-block;
  margin-right: 5px;
  margin: 0 10px;
}

.seekbar {
  z-index: 3;
  position: relative;
  width: 100%;
  background-color: transparent !important;
}

.progressbar,
.bufferbar {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  pointer-events: none;
  height: 1px;
  z-index: 2;
  /* Turns off styling - not usually needed, but good to know. */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* gets rid of default border in Firefox and Opera. */
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #c0392b;
}
.progressbar::-moz-progress-bar,
.bufferbar::-moz-progress-bar {
  background: #c0392b;
}
.progressbar::-webkit-progress-bar,
.bufferbar::-webkit-progress-bar {
  background: rgba(255, 255, 255, 0.1);
}
.progressbar::-webkit-progress-value,
.bufferbar::-webkit-progress-value {
  background: #c0392b;
}

.bufferbar {
  z-index: 1;
  color: #aaa;
}
.bufferbar::-moz-progress-bar {
  background: #aaa;
}
.bufferbar::-webkit-progress-value {
  background: #aaa;
}

.volumebar {
  width: 8%;
  margin: 0 1%;
}

.theory-next {
  color: #fbfbfd;
  font-weight: 500;
  font-size: 20px;
  background: #83b63b;
  border-radius: 8px;
  height: 64px;
  width: 305px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  margin-left: 30px;
  &--disabled {
    background: #c1c1c1;
    cursor: default;
    box-shadow: none;
    user-select: none;
  }
}
</style>
