import {computed, onMounted, onBeforeUnmount, ref, watch} from "vue";
import {useStore} from "vuex";



export default {
	name: 'TimerWidget',
	props: {
		timer: {
			type: Number
		},
		stopTimerForWidget: {
			type: Boolean,
		},
		startTimerForWidget: {
			type: Boolean
		}
	},
	setup (props, { attrs }) {
		const store = useStore();
		const timerTmp = props.timer ? ref(props.timer) : "20000";
		const setSeconds = ref(Number(Number(timerTmp.value) * 60)); //1200000
		const setNormalTime = ref('');
		const interval = ref(null);
		const activeModal = () => store.dispatch('ACTIVE_MODAL_EXAM', true);
		const t = ref(1000);
		const tranformTime = (millisec) => {
			let seconds = (millisec / 1000).toFixed(0);
			let minutes = Math.floor(seconds / 60);
			let hours = '00';
			if (minutes > 59) {
				hours = Math.floor(minutes / 60);
				hours = (hours >= 10) ? hours : "00" + hours;
				minutes = minutes - (hours * 60);
				minutes = (minutes >= 10) ? minutes : "00" + minutes;
			}
			seconds = Math.floor(seconds % 60);
			seconds = (seconds >= 10) ? seconds : "0" + seconds;
			return hours + ":" + minutes + ":" + seconds;
		}




		const start = () => {
			// console.log(interval.value);
			interval.value = setInterval(() => {
				if (setSeconds.value > 1000 && !props.stopTimerForWidget) {
					setSeconds.value = setSeconds.value - 1000
					setNormalTime.value = tranformTime(setSeconds.value - 1000)
				} else if (props.stopTimerForWidget == true) {
					return false
				} else if (setSeconds.value <= 1000  && !props.startTimerForWidget) {
					//setNormalTime.value = 0;

					clearInterval(interval);
					activeModal();

				}
			}, t.value);
		}
		const stop = () => {
			clearInterval(interval.value)
		}
		onMounted(() => {
			start()
		})
		onBeforeUnmount(() => {
			stop()
		})
		watch(
			() => props.stopTimerForWidget,
			() => {
				stop()
			}
		)
		watch(
			() => props.startTimerForWidget,
			() => {
				// clearInterval(interval.value)
				if (props.startTimerForWidget) {
					setSeconds.value = Number(Number(timerTmp.value) * 60); //1200000

					start();
				}


			}
		)
		return {
			tranformTime,
			setNormalTime,
			setSeconds

		}

	}
}
