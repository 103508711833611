import Aside from '../../components/Aside/Aside.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import ExamBlock from '../../components/ExamTest/ExamTest.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import Preloader from './../../components/Preloader/Preloader.vue'
import router from "../../router";

export default {
    name: 'ExamTest',
    components: {
        Aside,
        HeaderExam,
        ExamBlock,
        DownloadPage,
        Preloader,
    },
    props: {
        item: {
            type: String,
            required: false,
        },
        showHelpers: {
            type: String,
            required: false,
        },
        testTheory: {
          type: String,
          required: false,
        },
        testExam: {
            type: String,
            required: false,
        },
        idVideo: {
            type: String,
            required: false,
        },
        errors: {
            type: String,
            required: false,
        },
    },
    setup({ item, showHelpersProps, testTheoryProps, idVideo, testExamProps, errors }) {
        const store = useStore()
        const userId = ref('')
        const itemAll = item ? ref(JSON.parse(item)) : '';
        const errorCount = ref('');
        if (!itemAll.value) {
            router.push({
                name: 'Home',
            })
            setTimeout(() => {
                location.reload();
            })


        }
        const theory = ref('')
        const showHelpers = ref(showHelpersProps === 'true' ? true : false)
        const testTheory = ref(testTheoryProps === 'true' ? true : false)
        const testExam = ref(testExamProps === 'true' ? true : false)
        userId.value = localStorage.getItem('userData')
        let value = { id: itemAll.value.id, id_user: userId.value }
        let queryString = JSON.stringify(value)

        const getrulequestions = () =>
            store.dispatch('GET_RULE_QUESTIONS', queryString)

        onMounted(async () => {
            await getrulequestions()
            theory.value = await store.getters.THEORY_TEST
            errorCount.value = theory.value.length > 50 &&  testExam ? 3 : errors;
        })

        return {
            item,
            theory,
            showHelpers,
            testTheory,
            idVideo,
            errors,
            testExam,
            errorCount
        }
    },
}
