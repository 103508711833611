import Aside from '../../components/Aside/Aside.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import AlertModal from '../../components/AlertModal/AlertModal.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { computed, onMounted, ref } from 'vue'
import axios from 'axios'

import { base_URL } from '../../constants'
import { useStore } from 'vuex'
import TimerWidgetExamGIBDD from '../../components/TimerWidgetExamGIBDD/TimerWidgetExamGIBDD.vue'
import { boolean } from 'yup/es/locale'
import Comments from '../../components/Comments/Comments'

import Preloader from './../../components/Preloader/Preloader.vue'

export default {
    name: 'ExamGIBDD',
    components: {
        Aside,
        HeaderExam,
        DownloadPage,
        TimerWidgetExamGIBDD,
        AlertModal,
        Comments,
        Preloader,
    },
    setup() {
        const store = useStore()
        const theory = ref([])
        const randomTickets = ref([])
        const countTickets = ref('')
        const currentTicketNumber = ref(1)
        const currentAnswerNumber = ref('')
        const resultTestUnsuccessfully = ref(false)
        const resultModal = ref(false)
        const result = ref([])
        const unAnswerArr = ref([])
        const AnswerCount = ref(0)
        const showComments = ref(false)
        const count = ref(0)
        const showCard = ref(true)
        const finishResult = ref(false)
        const resultTestSuccess = ref(false)
        const additionalQuestionsNum = ref([])
        const addTime = ref('')
        const controls = ref(false)
        const scrollContainer = ref(null)

        let id_user = { id_user: localStorage.getItem('userData') }
        onMounted(async () => {
            fetchData()
            await unAnswerMethod()
            const activeModalExamGIBDD = () =>
                store.dispatch('ACTIVE_MODAL_EXAM_GIBDD', false)
            await activeModalExamGIBDD()
        })
        const userData = computed(() => store.getters.userInfo)

        const fetchData = async () => {
            let randomTickets = []

            // Генерация случайных 4 чисел
            function generateArrayRandomNumber(min, max) {
                var totalNumbers = max - min + 1,
                    arrayTotalNumbers = [],
                    arrayRandomNumbers = [],
                    tempRandomNumber

                while (totalNumbers--) {
                    arrayTotalNumbers.push(totalNumbers + min)
                }

                while (arrayTotalNumbers.length) {
                    tempRandomNumber = Math.round(
                        Math.random() * (arrayTotalNumbers.length - 1)
                    )
                    arrayRandomNumbers.push(arrayTotalNumbers[tempRandomNumber])
                    arrayTotalNumbers.splice(tempRandomNumber, 1)
                }
                return arrayRandomNumbers
            }

            const randomTicketNumbers = generateArrayRandomNumber(1, 40).slice(
                0,
                4
            )
            //Генерируем массив из 4х рандомных билетов

            //1й
            let requestArray = {
                number: randomTicketNumbers[0],
                id_user: id_user,
            }
            let queryString = JSON.stringify(requestArray)

            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, queryString)
                .then(response => {
                    randomTickets.push({
                        num: randomTicketNumbers[0],
                        res: response.data[0],
                        count: 0,
                    })
                })
                .catch(err => {
                    console.warn(err)
                })

            //2й
            requestArray = {
                number: randomTicketNumbers[1],
                id_user: id_user,
            }
            queryString = JSON.stringify(requestArray)

            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, queryString)
                .then(response => {
                    randomTickets.push({
                        num: randomTicketNumbers[1],
                        res: response.data[0],
                        count: 1,
                    })
                })
                .catch(err => {
                    console.warn(err)
                })
            //3й
            requestArray = {
                number: randomTicketNumbers[2],
                id_user: id_user,
            }
            queryString = JSON.stringify(requestArray)

            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, queryString)
                .then(response => {
                    randomTickets.push({
                        num: randomTicketNumbers[2],
                        res: response.data[0],
                        count: 2,
                    })
                })
                .catch(err => {
                    console.warn(err)
                })
            //4й
            requestArray = {
                number: randomTicketNumbers[3],
                id_user: id_user,
            }
            queryString = JSON.stringify(requestArray)

            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, queryString)
                .then(response => {
                    randomTickets.push({
                        num: randomTicketNumbers[3],
                        res: response.data[0],
                        count: 3,
                    })
                })
                .catch(err => {
                    console.warn(err)
                })
            let tickets = []
            randomTickets.forEach(item => {
                item.res.forEach(item2 => {
                    item2['num'] = item.num
                    item2['count'] = item.count
                })
                tickets.push(item.res)
            })

            theory.value = tickets[0].concat(
                tickets[1],
                tickets[2],
                tickets[3],
                tickets[4]
            )
            theory.value.pop()
            // console.log(randomTickets)
        }

        const AnswerTrueCount = computed(() => {
            let trueObject = theory.value.filter(item => item.result == 1)
            return trueObject.length
        })

        const AnswerFalseCount = computed(() => {
            let falseObject = theory.value.filter(item => item.result == 0)
            if (falseObject.length > 2) {
                showCard.value = true
                finishResult.value = true
                disabledAllQuest()
                resultModal.value = true
                resultTestUnsuccessfully.value = true
            }

            let names = Object.values(falseObject).map(item => item.num)
            const dups = names.filter(
                x => names.indexOf(x) !== names.lastIndexOf(x)
            )
            const res = names.reduce((ac, a, i) => {
                if (dups.includes(a)) {
                    ac.push(i)
                }
                return ac
            }, [])
            if (res.length >= 2) {
                showCard.value = true
                finishResult.value = true
                disabledAllQuest()
                resultModal.value = true
                resultTestUnsuccessfully.value = true
            }

            return falseObject.length
        })

        const CountBlock = computed(() => {
            count.value = theory.value.length / 5 - 1
            return count
        })

        const colorModal = computed(() => {
            if (resultTestUnsuccessfully.value) {
                return 'red';
            }
            else if (!resultTestUnsuccessfully.value && !resultTestUnsuccessfully.value && stopTimer.value ) {
                return 'red'
            }
            else {
                return 'green'
            }
            // return resultTestUnsuccessfully.value ? 'red' : 'green'
        })

        const stopTimer = computed(() => store.getters.MODAL_EXAM_GIBDD)

        const resultModalAll = computed(() => {
            if (resultModal.value == true) {
                return true
            } else if (stopTimer.value == true) {
                disabledAllQuest()
                return true
            } else if (resultModal.value == true && stopTimer.value == true) {
                disabledAllQuest()
                return true
            } else return false
        })
        const currentTicket = val => {
            currentTicketNumber.value = val
            currentAnswerNumber.value = ''
        }
        const showHideComment = () => {
            showComments.value = !showComments.value
        }

        const currentAnswer = (valTicket, val, asnw) => {
            if (theory.value[valTicket].disabled != 'Y') {
                //если не заблокирован ответ
                currentAnswerNumber.value = val
                theory.value[valTicket].currentAnsewer = val
                theory.value[valTicket].result = asnw.correct == 'Y' ? 1 : 0
            }
        }

        const unAnswerMethod = () => {
            unAnswerArr.value = []
            theory.value.forEach(function(item, i, arr) {
                if (item.result != 0 && item.result != 1) {
                    unAnswerArr.value.push(i + 1)
                }
            })
        }
        const nextQuestion = (index, num) => {
            unAnswerMethod()
            AnswerCount.value = AnswerTrueCount.value + AnswerFalseCount.value
            if (unAnswerArr.value.length > 0) {
                if (index + 1 >= Object.keys(theory.value).length) {
                    //если это последний билет
                    currentTicketNumber.value = 0
                    currentTicketNumber.value = unAnswerArr.value[0]
                    scrollContainer.value.scroll(
                        (currentTicketNumber.value - 5) * 52,
                        0
                    )
                    // currentAnswerNumber.value = ''
                    if (
                        theory.value[index].result == 1 ||
                        theory.value[index].result == 0
                    ) {
                        theory.value[index].disabled = 'Y'
                        if (theory.value[index].result == 0) {
                            additionalQuestionsNum.value.push(num)
                            //addNewTickets(num)
                            additionalQuestionsNum.value.push(num)
                        }
                    }
                } else {
                    if (
                        theory.value[index].result == 1 ||
                        theory.value[index].result == 0
                    ) {
                        theory.value[index].disabled = 'Y'
                        currentTicketNumber.value = unAnswerArr.value[0]
                        if (theory.value[index].result == 0) {
                            //addNewTickets(num)
                            additionalQuestionsNum.value.push(num)
                        }
                    } else {
                        currentTicketNumber.value += 1
                        scrollContainer.value.scroll(
                            (currentTicketNumber.value - 5) * 52,
                            0
                        )
                    }
                    currentAnswerNumber.value = ''
                }
            } else {
                if (additionalQuestionsNum.value.length > 0) {
                    additionalQuestionsNum.value.forEach(function(
                        item,
                        i,
                        arr
                    ) {
                        addNewTickets(item)
                    })
                    if (additionalQuestionsNum.value.length == 5) {
                        addTime.value = 300000 //5 мин
                    } else {
                        addTime.value = 600000 //10 мин
                    }

                    additionalQuestionsNum.value = []
                } else {
                    finishResult.value = true
                    showCard.value = true
                    resultTestSuccess.value = true
                    resultModal.value = true
                }
            }
        }
        const repeatTest = async index => {
            theory.value = item
            await unAnswerMethod()
            currentTicketNumber.value = 1
            currentAnswerNumber.value = ''
            unAnswerArr.value = []
            resultTest.value = false
        }
        const addNewTickets = async number => {
            let hideTickets = theory.value.filter(item => item.num == number)
            controls.value = true;
            let requestArray = {
                number: number,
                id_user: id_user,
            }
            let queryString = JSON.stringify(requestArray)

            let newTicktsArr = []
            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, queryString)
                .then(response => {
                    newTicktsArr.push({
                        num: number,
                        res: response.data[1],
                        count: CountBlock.value.value + 1,
                    })
                })
                .catch(err => {
                    console.warn(err)
                })
            // console.log('newTicktsArr', newTicktsArr)
            let ticketsNew = []
            newTicktsArr.forEach(item => {
                item.res.forEach(item2 => {
                    item2['num'] = item.num
                    item2['count'] = item.count
                })
                ticketsNew.push(item.res)
            })
            theory.value = theory.value.concat(ticketsNew[0])
            // console.log('hideTickets', theory.value)
        }
        const addFavorite = async (val, item, index) => {
            // console.log('val', val)
            let number = [val]
            let value = { id: userData.value.id, questions: number, item: item }
            let queryString = JSON.stringify(value)
            const addFavor = () => store.dispatch('GET_ADD_FAVOR', queryString)
            addFavor()
            theory.value[index].favorite = true
        }
        const delFavorite = (question_id, index) => {
            let user_id = userData.value.id
            store.dispatch('DEL_FAVORITES', { user_id, question_id })
            theory.value[index].favorite = false
        }

        const selectCard = async index => {
            showCard.value = !showCard.value
            currentTicketNumber.value = index + 1
        }
        const disabledAllQuest = async () => {
            theory.value.forEach(function(item, i, arr) {
                item.disabled = 'Y'
            })
        }
        const closeModal = async () => {
            resultModal.value = false
            const activeModalExamGIBDD = () =>
                store.dispatch('ACTIVE_MODAL_EXAM_GIBDD', false)
            activeModalExamGIBDD()
        }

        const next = async () => {
            const res = await scrollContainer.value
            scrollContainer.value.scroll(res.scrollLeft + 52, 0)
        }
        const prev = async () => {
            const res = await scrollContainer.value
            scrollContainer.value.scroll(res.scrollLeft - 52, 0)
        }

        return {
            currentTicket,
            currentTicketNumber,
            currentAnswer,
            currentAnswerNumber,
            result,
            nextQuestion,
            repeatTest,
            theory,
            countTickets,
            unAnswerArr,
            AnswerTrueCount,
            AnswerFalseCount,
            resultTestUnsuccessfully,
            resultModal,
            unAnswerMethod,
            AnswerCount,
            showHideComment,
            showComments,
            addFavorite,
            delFavorite,
            addNewTickets,
            CountBlock,
            count,
            showCard,
            selectCard,
            finishResult,
            closeModal,
            stopTimer,
            resultModalAll,
            resultTestSuccess,
            colorModal,
            additionalQuestionsNum,
            addTime,
            next,
            prev,
            controls,
            scrollContainer,
        }
    },
}
