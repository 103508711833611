import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'TimerWidgetExamGIBDD',
    props: {
        stopTimer: {
            type: Boolean,
        },
        addTime: {
            type: Number,
        },
    },
    setup(props, { attrs }) {
        const store = useStore()
        const setSeconds = ref(1200000) //1200000
        const setNormalTime = ref('')
        const interval = ref(null)

        const activeModalExamGIBDD = () =>
            store.dispatch('ACTIVE_MODAL_EXAM_GIBDD', true)
        const tranformTime = millisec => {
            let seconds = (millisec / 1000).toFixed(0)
            let minutes = Math.floor(seconds / 60)
            let hours = '00'
            if (minutes > 59) {
                hours = Math.floor(minutes / 60)
                hours = hours >= 10 ? hours : '00' + hours
                minutes = minutes - hours * 60
                minutes = minutes >= 10 ? minutes : '00' + minutes
            }
            seconds = Math.floor(seconds % 60)
            seconds = seconds >= 10 ? seconds : '0' + seconds
            minutes = minutes >= 10 ? minutes : '0' + minutes
            return hours + ':' + minutes + ':' + seconds
        }

        const start = () => {
            interval.value = setInterval(() => {
                if (setSeconds.value > 1000 && !props.stopTimer) {
                    setSeconds.value = setSeconds.value - 1000
                    setNormalTime.value = tranformTime(setSeconds.value - 1000)
                } else if (props.stopTimer == true) {
                    return false
                } else if (setSeconds.value <= 1000) {
                    //setNormalTime.value = 0;

                    clearInterval(interval.value)
                    setSeconds.value = 0
                    activeModalExamGIBDD()
                    // console.log(activeModalExamGIBDD())
                }
            }, 1000)
        }
        const stop = () => {
            clearInterval(interval.value)
        }

        onMounted(() => {
            start()
        })

        watch(
            () => props.stopTimer,
            () => {
                stop()
            }
        )
        watch(
            () => props.addTime,
            () => {
                setSeconds.value += Number(props.addTime)
            }
        )

        return {
            tranformTime,
            setNormalTime,
            setSeconds
        }
    },
}
